<template>
  <div class="r-sticky-container">
    <div
      ref="container"
      :class="{
        'absolute bottom-0 left-0 right-0 p-4 bg-white flex items-center justify-center z-20':
          sticky,
      }"
    >
      <slot></slot>
    </div>
    <div
      v-if="sticky && reserveSpace"
      class="h-20"
      :style="{ height: realContainerHeight + 'px' }"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'RStickyContainer',
  props: {
    sticky: {
      type: Boolean,
      default: false,
      required: false,
    },
    reserveSpace: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      realContainerHeight: -1,
      observer: null,
    };
  },
  beforeUnmount() {
    if (this.observer !== null) {
      this.observer.disconnect();
    }
  },
  mounted() {
    this.observer = new ResizeObserver(() => this.calcContainerHeight);
    this.observer.observe(this.$refs.container);
  },
  methods: {
    calcContainerHeight() {
      if (!this.$refs.container) {
        this.realContainerHeight = -1;
      }
      this.realContainerHeight = this.$refs.container.clientHeight;
    },
  },
};
</script>
